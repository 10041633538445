.questions-container {
  text-align: center;
  color: #EAEAEA;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #1F1F1F;
}

.questions-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #FF5733;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}

.questions-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.questions-button {
  background-color: #FF5733;
  color: #EAEAEA;
  padding: 15px 30px;
  margin: 10px;
  border: 1px solid #FF5733;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .questions-container {
    padding: 10px;
  }

  .questions-title {
    font-size: 1.5rem;
  }

  .questions-form {
    width: 90%; 
    padding: 15px;
  }

  .questions-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}
