.home-container {
  text-align: center;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%; 
  background-color: #1F1F1F;
}
.home-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #FF5733;
  font-weight: bold;
}

.home-text {
  font-size: 1.2rem;
  color: #EAEAEA;
  margin-bottom: 1rem;
}

.auth-buttons {
  display: flex;
  flex-direction: column;
}

.button {
  background-color: #333333;
  color: #FF5733;
  padding: 15px 30px;
  margin: 10px;
  border: 1px solid #FF5733;
  text-decoration: none;
  font-weight: bold;
}

.button:hover {
  background-color: #444444;
}
  
  @media (max-width: 768px) {
    .home-title {
      font-size: 1.5rem;
    }
    .home-text {
      font-size: 1rem;
    }
  }
  