.nav-container {
  color: #EAEAEA;
  width: 100%;
  padding: 10px 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
}

.nav-button {
  margin: 0 20px;
  background-color: #FF5733;
  color: #EAEAEA;
  border: 1px solid #FF5733;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.nav-button:hover {
  background-color: #444444;
}

@media only screen and (max-width: 768px) {
  .nav-button {
    margin: 0 10px;
    padding: 8px 16px;
  }
}
