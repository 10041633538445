form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background-color: #1F1F1F;
  color: #EAEAEA;
  border: 1px solid #FF5733;
  border-radius: 4px;
}

input::placeholder {
  color: #EAEAEA;
  opacity: 0.7;
}

button {
  background-color: #FF5733;
  color: #EAEAEA;
  padding: 15px 30px;
  margin: 10px;
  border: 1px solid #FF5733;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

button:hover {
  background-color: #444444;
}

.invalid-input {
  border: 2px solid red;
}

.error-text {
  color: red;
  font-size: 0.8em;
}
