body {
  margin: 0;
  padding: 0;
  background-color: #1F1F1F;
  font-family: 'Open Sans', sans-serif;
  color: #EAEAEA;
}

h1, h2, h3 {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  color: #FFFFFF;
}

.container {
  background-color: #333333;
  color: #EAEAEA;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .container {
    padding: 30px;
  }
}
