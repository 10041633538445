.sign-up-page-container {
  text-align: center;
  color: #EAEAEA;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #1F1F1F;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #FF5733;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}
