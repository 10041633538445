.calorie-counter {
  background-color: #1F1F1F;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  color: #EAEAEA;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.calorie-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #FF5733;
  font-weight: bold;
}

.calorie-description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #EAEAEA;
}

.calorie-chart {
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-bottom: 2rem;
}

.calorie-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.calorie-input {
  width: 80%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #FF5733;
  border-radius: 4px;
  background-color: #333333;
  color: #EAEAEA;
}

.calorie-button {
  padding: 10px 20px;
  background-color: #FF5733;
  border: 1px solid #FF5733;
  border-radius: 4px;
  color: #EAEAEA;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.calorie-button:hover {
  background-color: #444444;
}

@media (min-width: 480px) {
  .calorie-form {
    flex-direction: row;
  }
  .calorie-input {
    width: 60%;
    margin-right: 10px;
    margin-bottom: 0;
  }
}
